.product-item {
  position: relative;
  text-align: center;

    &:hover {
      .product-item__img img+img {
        opacity: 1;
      }
    }
}

.add-to-wishlist-catalog, .remove-from-wishlist-catalog, .add-to-wishlist-catalog_not-logged {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  z-index: 4;
  cursor: pointer;

  img {
    width: 22px;
  }
}


.close-popup {
  position: absolute;
  right: 16px;
  width: 16px;
  cursor: pointer;

  &.wishlist {
    right: 48px;
    top: 30px;
  }
}

.play-vimeo {
  position: absolute;
  width: 40%;
  left: 30%;
  height: 30%;
  top: 33%;
  border: none !important;
  padding: 0 !important;
}

.product-item__img {
  position: relative;
  margin: 0 0 10px;

    img+img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }
}

.product-item__brand {
  @include caslon;
  font-size: 24px;
  margin-bottom: 2px;
  @include breakpoint(small down) {
    font-size: 18px;
  }
}

.product-item__descr {
  @include brandon-bold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.05em;
}

.product-item__price {
  /*display: block;*/
  font-size: 20px;
  line-height: normal;
  margin-top: 10px;
  padding-bottom: 5px;
  @include brandon-medium;
}

.product-item__retail-price {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $text-grey;
  margin-top: -1px;
  display: block;
}

.product-item__price-block {
  padding: 5px 0;
  border-top: 1px solid $line-color;

  &.list {
    display: block;

    .iva {
      margin-top: 10px;
      padding-bottom: 5px;
    }
  }

  span.discount{
    color: $grey-border;
    font-size: 20px;
    margin-left: 10px;
    text-transform: inherit;

    &.linedthrough{
      text-decoration: line-through;
    }
  }
  }

.product-item{
    &:hover {
      .product-item__price-block {
        border-top: 1px solid $gold;
      }
  }
    &:active {
      .product-item__price-block {
        border-top: 1px solid $gold;
      }
    }
}

.product-item__link {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
}

.product-item__table {
  @include breakpoint(small down) {
    margin: 10px 0px;
  }
}

.product-item__description {
  border-top: 1px solid $line-color;
  padding-top: 15px;
}

.product-code{
  font-size:14px;
}

//catalog griglia

.prompt-delivery{
  border: 1px solid $gold;
  border-radius: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 5px auto 0;
  
  &.expanded{
    margin: 5px 0px;
  }
  p{
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
  }

  &.size{
    border: none;
    margin: 10px 0px 0px 10px;

    p {
      color: $gold;
      text-align: center;
    }
  }
}

span.prompt-delivery{
      color: $gold;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
}

.rate-price{
  .save{
    text-align: left;
    font-size: 18px;
    margin-top: 4px;
    color: $grey-border;
    text-transform: inherit;
    letter-spacing: 0.3px;
  }
  .price{
    color: $grey-border;
    font-size: 18px;
    margin-left: 10px;
  }
}

.product-item.relative {
  .add-to-wishlist-catalog {
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }
  }
  &:hover {
    .add-to-wishlist-catalog {
      display: block;
    } 
  } 
}

.label-tech {
  margin-top: -20px;
  font-size: 18px;
}


.slick-lightbox .slick-next {
  right: 30px !important;
}

.product-lightgallery {
  cursor: pointer;
}