#product-slider-nav .slick-slide {
  padding: 0 5px;
}

.product-container {
  margin: 40px 0 50px 0;
  @include breakpoint(small down) {
    margin: 12px 0;
  }
}

.slider-wrapper {
  margin-right: 50px;
  @include breakpoint(medium down) {
    margin-right: 0;
  }
}


.sample-slick-item {
  width: 100px !important;
  height: 100px !important;
  display: flex !important;
  position: relative;
  align-items: flex-end;
}

.product-slider {
  margin-bottom: 10px;
}

.delete-icon {
  color: crimson;
  position: absolute;
  right: 3px;
  top: 4px;
  transform: scale(1.4);
  cursor: pointer;
}

.height-58 {
  height: 58px;
}

.height-0 {
  height: 0px;
}

#product-slider-nav .slick-track .slick-current img {
  border-bottom: 1px solid $gold;
  padding-bottom: 5px;
}

.nav-slide {
  border-bottom: 1px solid $line-color;
  padding-bottom: 5px;
}

.list-tag {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include breakpoint(small down) {
    margin-top: 20px;
  }

    li {
      margin: 0 5px;
      @include breakpoint(small down) {
        margin: 0 2px 2px;
      }
    }

    a {
      @include brandon-bold;
      @include transition-basic;
      color: $text-grey;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      border: 1px solid $line-color;
      padding: 5px 15px 3px;

        &:hover {
          color: $black;
          border-color: $black;
        }
    }
}

.sample-image {
  &:hover {
    box-shadow: 1px 2px 12px rgba(49, 49, 49, 0.51);
  }
}

.product-info__title {
  @include caslon;
  font-size: 36px;
  margin: 14px 0;
  @include breakpoint(small down) {
    font-size: 28px;
  }
}

.product-info__subtitle {
  text-transform: uppercase;
  font-size: 15px;
  padding-bottom: 10px;
  letter-spacing: 0.05em;
  @include breakpoint(small down) {
    font-size: 11px;
  }
}
.product-info__descr {
  font-size: 18px;
  @include breakpoint(small down) {
    font-size: 16px;
  }
}

.product-info__price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 15px;
}

.product-info__single-price {
  @include brandon-bold;
  font-size: 22px;
  @include breakpoint(small down) {
    font-size: 19px;
  }

    span {
      /*display: block;*/
      text-transform: uppercase;
      font-size: 32px;
      letter-spacing: 0.05em;
      margin-bottom: -6px;

      &.vat {
        font-size: 18px;
        @include brandon-regular;
        font-weight: 400;
        margin-left: 10px;
      }

      &.discount{
        color: $grey-border;
        font-size: 20px;
        margin-left: 10px;
        text-transform: inherit;

      &.linedthrough{
        text-decoration: line-through;
      }
      }
    }

    /*span:last-child {
      font-size: 32px;
    }
    */

    &.-light {
      opacity: .4;
    }
}

.product-info__size-table {
  text-align: center;
  margin: 20px 0 45px;

    a {
      @include brandon-bold;
      @include transition-basic;
      color: $text-grey;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      border: 1px solid $line-color;
      padding: 5px 15px 3px;

      &:hover {
        color: $black;
        border-color: $black;
      }
    }
}

.i-number-btn {
  border: 1px solid $black;
  display: flex;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;

    img {
      width: 14px;
    }
}

.qty-input-container {
  display: flex;
  margin-top:15px;
  align-items: initial;
  justify-content: left;
  position: relative;
  top: -1px;

  &.cart{
    justify-content: center;
  }
}

.i-number {
  margin: 0;
  height: 35px;
  box-shadow: none;
  width: 70px;
  text-align: center;
  border: none;
  background-color: white;
  cursor: default;
}

.qty-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

    th {
      @include brandon-medium;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .05em;
      padding-bottom: 5px;
      padding-top: 5px;
    }

    td {
      border-top: 1px solid $line-color;
      justify-content: space-evenly;
      padding: 8px 0px;
      align-items: center;
      @include brandon-regular;
  
      &.bold {
        @include brandon-medium;
      }
    }

    .availability {
      opacity: .4;
    }
}

.qty-max {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 1px solid $black;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 8px;
  position: relative;

    &:active {
      top: 1px;
    }
}

.qty-table-resume {
  width: 100%;
  text-align: center;
  border-collapse: collapse;

    button {
      @include brandon-bold;
      background-color: $black;
      color: white;
      width: 182px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.1em;
      padding: 9px 0 7px;
      position: relative;

        &:active {
          top: 1px;
        }
    }

    span {
      @include brandon-bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: .05em;

        &:first-child {
          font-size: 11px;
          padding-top: 5px;
        }
    }

    td {
      border-bottom: 1px solid $line-color;
      padding: 25px 0;

        &:first-child {
          opacity: .4;
        }
    }
}

.size-col-1 {
  width: 25%;
}

.size-col-2 {
  width: 25%;
}

.size-col-3 {
  width: 50%;
}

.price-total {
  text-align: center;
  padding: 18px 0 12px;

    span {
      @include brandon-bold;
      display: block;
      text-transform: uppercase;
      letter-spacing: .05em;

      &:first-child {
        font-size: 11px;
        margin-bottom: -6px;
      }

      &:last-child {
        font-size: 22px;
      }
    }
}

.related-title {
  text-align: center;
  color: $black;
  margin: 60px 0 40px;
  padding: 0 0 10px;
  @include breakpoint(small down) {
    font-size: 30px;
    margin: 50px 0 30px;
    padding: 0 0 6px;
  }
}

.list-colors-wrapper {
  text-align: center;
  border-bottom: 1px solid $line-color;
  padding: 25px 0 28px;

    p {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: -2px;
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}

.list-colors {

    li {
      display: inline-block;
      margin: 0 8px;
    }

    span {
      @include transition-basic;
      width: 58px;
      height: 5px;
      display: inline-block;
      @include breakpoint(small down) {
        width: 40px;
        height: 8px;
      }
    }

    a:hover,
    a.active {
      span {
        transform: scaleY(2.8);
      }
    }
}

.code-product{
  font-size: 15px;

  &.expanded {
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

.gocheckout-sample {
  display: flex;
  justify-content: center;
}

.product-name-code{
  border-bottom: 1px solid $gold;
  padding-bottom: 10px;
}

.sample-empty {
  background: #d3d3d347;
  height: 90px;
  width: 100px;
}

.close-sample {
  position:absolute;
  top:10px;
  right:16px;
  cursor: pointer;
  width: 20px;
}

//slider

#product-slider {
  
  .slick-center {
      opacity: 1;
  }

  .slick-dots li button:before {
      color: $black;
      font-size: 10px;
      line-height: 30px;
  }

  .slick-dots li.slick-active button:before {
      color: $gold;
  }

  .slick-next:before,
  .slick-prev:before {
      color: $gold;
      font-size: 40px;
  }

  .slick-prev {
      left: 12px;
      height: 40px;
      width: 40px;
      z-index: 1;
  }

  .slick-next {
      right: 12px;
      height: 40px;
      width: 40px;
      z-index: 1;
  }

  .slick-prev:before {
      content: url(/skins/b2bopificio/images/icons/freccia-sx-slider.svg);
  }

  .slick-next:before {
      content: url(/skins/b2bopificio/images/icons/freccia-dx-slider.svg);
  }
}

.product-details {
  @include breakpoint(small down) {
    font-size: 16px;
  }
  .detail.-right {
    @include brandon-medium;
    font-size: 18px;
    line-height: 30px;
    @include breakpoint(small down) {
      font-size: 16px;
    }
  }

  .detail p {
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 29px;
    @include breakpoint(small down) {
      font-size: 16px;
    }
  }

}

.add-wishlist{
  border-top: 1px solid $gold;
  padding-top: 25px;
}

.quantity-cart{
  padding: 0px 18px;
}
//color swatch

.product-colors {
  li a {
    display: block;
    background-position: 50%;
  }
}

.product-colors {
  margin: 0 0 12px;
  padding: 0 0 14px;
  list-style: none;
  border-bottom: 1px solid $gold;

    li {
      display: inline-block;
      padding: 2px;
      width: 13.692%;
      cursor: pointer;

        a {
          display: block;
          height: 45px;
          background-position: center center;
          cursor: pointer;
        }

        &.active a {
          box-shadow: inset 0 0 0 2px $gold;
        }
    }

}

.img-wishlist{
  margin: 0px 0px 4px 4px;
}

//banner campione
.container_banner-campione{
  background-color: $light-grey;
  padding: 19px 28px;
  @include breakpoint(small down) {
    padding: 19px 19px;
  }

  &.home {
    padding: 50px 28px;
  }
}
.title-banner-container{
  font-size: 26px;
  margin-bottom: 15px;
}
.description-banner-container{
  p {
    font-size: 18px;
    margin-top: 20px;
  }
}
.banner-campione{
  padding: 17px;

  &.home {
    padding-top: 0px;
  }
}

.container_banner-image{
  background-image: url(/skins/b2bopificio/images/product/tessuto-popup.jpg);
  background-position: center center;
}

.color-title {
  border: none;
  color: $black;
  font-size: 20px;
  padding: 1rem 0;
  font-family: caslon,Georgia,serif;
  font-style: normal;
  font-weight: 300;
}

.unclickable {
  pointer-events: none;
}

.size-product {
  margin: 0;
  padding: 0;
  list-style: none;

    > div {
      display: inline-block;
      padding: 2px;
      width: 32%;

      @include breakpoint(small down) {
        width: 32%;
      }

      input[type="radio"] + label {
        background-color: $light-grey;
        border: 1px solid $light-grey;
        padding: 8px 0 6px;
        font-size: 14px;
        margin: 0;
        font-weight: 700;
        transition: 150ms;
        width: 100%;
        text-align: center;
        line-height: 1.8;
      }

      input {
        display: none;
      }

      input[type="radio"]:checked + label,
      input[type="radio"] + label:hover {
        background-color :$gold;
        color: $white;
      }

      span {
        background-color: $light-grey;
        color: #989898;
        padding: 8px 0 6px;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        text-align: center;
        display: inline-block;
        cursor: default;
        line-height: 1.8;
      }
    }
}

.title-size{
  font-size: 20px;
  margin-top:15px;
}
.etichette-uso{
  height: 35px;
}

.detail > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.div-already-wishlist {
  margin-top: 0px;
}

.add-wishlist{
  border-top: 1px solid $gold;
  padding-top: 25px;
}

.add-to-wishlist {
  margin-top: 0px;
}

.img-wishlist{
  margin: 0px 0px 4px 4px;
}