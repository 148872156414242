#cart-container {
  margin-bottom: 60px;
  padding-top: 30px;
    @include breakpoint(medium) {
      padding-top: 40px;
  }

  @include breakpoint(small) {
    padding-top: 0px;
}
}
.cart-title {
  color: $black;
  font-size: 30px;
  padding: 0 0 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    font-size: 30px;
    margin: 35px 0 25px;
  }
}

.cart-product {
  border-bottom: 1px solid $line-color;
  margin-bottom: 35px;
}

.cart-product__title {
  font-size: 20px;
@include breakpoint(medium down) {
  margin-top: 20px;
}
}

.cart-product__subtitle {
  font-size: 16px;
  display: block;
  margin: 2px 0;
  &.code {
    font-size: 14px;
    line-height: 24px;
  }
}

/*.cart-product__col-1 {
  width: 35%;
}

.cart-product__col-2 {
  width: 65%;
}
*/

.btn-cart-remove {
    cursor: pointer;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 7px;

    img {
        width: 19px;
    }
}

.cart-product__buttons {
  text-align: center;
  padding: 35px 0;

    button {
      @include brandon-bold;
      background-color: $black;
      border: 1px solid $black;
      color: white;
      width: 182px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .1em;
      padding: 9px 0 7px;
      position: relative;
      margin: 0 5px;

        &.-light {
          color: $black;
          background-color: white;
        }

        &:active {
          top: 1px;
        }
    }
}

.cart-product__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 30px;

    span {
      @include brandon-bold;
      display: block;
    }

    small {
      font-weight: bold;
      text-decoration: line-through;
      color: $text-grey;
    }
}

.cart-product__price-label {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .05em;
}

.cart-product__price-big {
  font-size: 22px;
  margin-top: -5px;
}

.cart-resume {
  margin-left: 50px;
  @include breakpoint(medium down) {
    margin-left: 0;
  }
}

.cart-resume__top {
  //margin-bottom: 20px;

    p {
      @include brandon-medium;
      font-size: 16px;
      line-height: 1.8;
      text-align: left;
      margin-bottom:0px;

      &.right{
        text-align:right;
      }

        &.-light {
          opacity: .6;
        }
    }

    &.-right {
      text-align: left;

        p {
          font-size: 16px;
          line-height: 1.8;
        }
    }
}

.coupon-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

    > * {
      width: 50%;
    }

    input {
      margin: 0 10px 0 0;
      height: 40px;
      box-shadow: none;
      border-color: $line-color;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.04em;
      font-size: 14px;
      padding: 8px 8px 6px;
      color: $black;
    }
/*
    .btn {
      font-size: 14px;
      background-color: $gold;
      color: $white;
      padding: 10px 0 8px;
    }
    */
}

.coupon-label {
  font-size: 16px;
  margin-bottom: 4px;
  transition: all .15s ease-in-out;
  top: 6px;
  left: 14px;
  text-transform: uppercase;
  color: $black;
  font-size: 14px;
}

.country-select {
  @include brandon-bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 12px;
  height: 34px;
  padding-left: 11px;
  border-color: $line-color;
}

.cart-total {
  padding: 20px 0;
  text-align: left;

    span {
      @include brandon-medium;
      display: block;
    }
}

.cart-total__label {
  font-size: 22px;
  margin-top: -5px;
  text-align: left;
  text-transform: uppercase;
}

.cart-total__price {
  font-size: 22px;
  margin-top: -5px;
  text-align: right;
}

.select-carrier {
  margin: 0 0 15px;
}

/*.cart-resume__space {
  margin: 0 0 15px;
}*/

.cart-dimension{
  font-size: 16px;
  margin: 2px 0;
}
.cart-update .qty-input-container{
  margin-top: 3px;
}

.description-product_cart{
  display: flex;
  align-items: center;
}

.shareWishlistButton {
  text-align: left;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  > * {
    color: $gold;
    margin-bottom:0px;
  }
}

hr.share-wishlist {
  border-top: 1px solid $line-color;
}

.reveal {
  &.wishlist {
    @include breakpoint(medium down) {
      margin: 0 auto;
      width: 90%;
      height: auto;
      min-height: auto;
      top: 20%!important;
    }
  }
}

.share-button-div {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;  
}


.share-button {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.whatsapp {
    background: $green-whatsapp;
  }
  &.mail {
    background: $gold;
  }
  &.facebook {
    background:$blue-facebook;
  }
  > a > img {
    width: 24px;
    margin-bottom: 2px;
  }
}

.popupShareLink {
  border: 1px solid $line-color;
  padding: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: none;
}

.popupShareLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gold;
  color: $white;
}

.taxable-price {
  &.cart {
    color: $grey-border;
    text-decoration: line-through;
    margin-right: 8px;
  }
}

.cart-resume__space {
  overflow: hidden;
}