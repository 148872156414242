/* Animation */
@-webkit-keyframes 
pulsate {  0% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 0.8;
}
 45% {
 -webkit-transform: scale(1.75);
 transform: scale(1.75);
 opacity: 0;
}
}
@keyframes 
pulsate {  0% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 0.8;
}
 45% {
 -webkit-transform: scale(1.75);
 transform: scale(1.75);
 opacity: 0;
}
}

body { margin: 0; }

/* Hotspot */

.hotspot-container {
  background-color: #ededed;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.hotspot-container .img-responsive { max-width: 100%; }

.hotspot-container .hot-spot {
  cursor: pointer;
  background-image: url(/skins/b2bopificio/images/icons/marker-sllider.svg);
  border-radius: 50%;
  position: absolute;
  height: 25px;
  width: 26px;
}

.hotspot-container .hot-spot .circle {
  display: block;
  position: absolute;
  top: 45%;
  left: 45%;
  width: 2em;
  height: 2em;
  margin: -1em auto auto -1em;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  border-radius: 50%;
  border: 1px solid #E5008A;
  opacity: 0;
  -webkit-animation: pulsate 3s ease-out infinite;
  animation: pulsate 3s ease-out infinite;
}

.hotspot-container .hot-spot .tooltip {
  background-color: white;
  border: 1px solid #b79962;
  display: none;
  font-size: 19px;
  opacity: 1.0;
  left: 12px;
  position: absolute;
  text-align: left;
  top: 37px;
  width: 195px;
  z-index: 999;
}

.hotspot-container .hot-spot .tooltip .img-row {
  padding: 10px;
  text-align: center;
}

.hotspot-container .hot-spot .tooltip .text-row { padding: 5px; }

.hotspot-container .hot-spot .tooltip div {
  color: black;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  font-family: 'caslon',Georgia,serif;
  font-style: normal;
  font-weight: 300;
}

.hotspot-container .hot-spot .tooltip p {
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 10px;
}

.hotspot-container .hot-spot .tooltip p:last-child { margin-bottom: 0; }

.arrow_box:after, .arrow_box:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(0, 0, 0, 0);
	border-bottom-color: white;
	border-width: 10px;
	margin-left: -10px;
}
.arrow_box:before {
	border-color: rgba(183, 153, 98, 0);
	border-bottom-color: #b79962;
	border-width: 12px;
	margin-left: -12px;
}