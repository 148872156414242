.cart-summary {
  margin-left: 40px;
  @include breakpoint(medium down) {
    margin-left: 0;
  }

    h2 {
      @include breakpoint(small down) {
        margin: 20px 0 10px;
      }
    }
}

.cart-summary__line {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 4px 0;

    span {
      @include brandon-bold;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .label {
      font-size: 11px;
    }
}

.cart-summary__separator {
  border: none;
  border-top: 1px solid $line-color;
  margin: 8px 0 10px;
}

.btn-cart-update{
    cursor: pointer;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 7px;
    padding: 0px;
    
    & img{
      width: 17px;
    }
}

.margin-top-info-cart{
  @media (min-width: 481px) and (max-width: 767px) {
   margin-top: 65px;
  }
}