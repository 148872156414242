@mixin caslon {
  font-family: 'caslon', Georgia, serif;
  font-style: normal;
  font-weight: 300;

}

@mixin brandon-light {
  font-family: 'brandon-light', Arial, Helvetica, sans-serif;
  font-style: normal;
}

@mixin brandon-regular {
  font-family: 'brandon-regular', Arial, Helvetica, sans-serif;
  font-style: normal;

}

@mixin brandon-medium {
  font-family: 'brandon-medium', Arial, Helvetica, sans-serif;
  font-style: normal;

}

@mixin brandon-bold {
  font-family: 'brandon-bold', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
}


@mixin transition-basic {
  transition: all .15s ease-in-out;
}