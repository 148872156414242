.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include brandon-bold;
      font-size: 100px;
      line-height: 1;
      letter-spacing: 0.05em;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
    }

    p {
      margin: 3px 0 25px;
    }
}

.blog-date {
  margin-top:20px;
}

.blog-date-archive {
  cursor: pointer;

  &:hover{
    color:$gold;
  }
}

.cms-page{
  margin:20px 0px 20px 0px;

  u {
    text-decoration: underline;
  }
  
  h1{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 20px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h2{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 16px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h3,h4{
    @include brandon-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 14px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  
  
  ul{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  ol{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
}

.cms-cover-title{
  color: $white;
  font-size:40px;
  margin-top:30px;
  @include breakpoint(small down) {
   font-size:30px;
  }

  &.coming-soon {
    font-size: 120px;
    line-height: 124px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
    @include breakpoint(medium down) {
      justify-content: flex-end;
      font-size:80px;
      line-height: 88px;
     }
  }
}

.coming-soon-container {
  padding: 72px 0;
  text-shadow: none;
}

.list-tessuti{
  list-style-image: url(/skins/b2bopificio/images/icons/list.svg);
  margin-left: 22px;
}


//ebt
.ebt-loghi{
  margin-left:120px;
  @include breakpoint(medium down) {
    margin-left:60px;
  }
}

.mobile-ebt{
@include breakpoint(small down) {
  margin-left:0px;
  margin-bottom:20px;
}
}

//slider ebt

#img-ebt{

.slick-dots li button:before{
  color: $black;
  font-size: 10px;
  line-height: 30px;
}

.slick-dots li.slick-active button:before {
  color: $gold;
  opacity: 1;
}

.slick-next:before, .slick-prev:before{
  color: $gold;
  font-size:40px;
  opacity:1;
}

.slick-prev{
  left:40px;
  z-index:1000;
}
.slick-next {
  right: 60px;
  z-index:1000;
}

.slick-prev:before {
  content: url(/skins/b2bopificio/images/icons/freccia-sx-slider.svg);
}

.slick-next:before {
  content: url(/skins/b2bopificio/images/icons/freccia-dx-slider.svg);
}
}

//masonry gallery no text
.masonry { 
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px; /* Adjustment for the gutter */
  width: 100%;
  @include breakpoint(small down) {
    margin-left:0px;
  }
  @include breakpoint(medium down) {
    margin-left: 0px;
  }
}

.masonry-brick {
  flex: auto;
  height: 250px;
  min-width: 150px;
  margin: 0 8px 8px 0; /* Some gutter */
  @include breakpoint(small down) {
    margin-right: 0px;
}
@include breakpoint(medium down) {
  margin-right: 0px;
}
}

.masonry-brick {
  &:nth-child(4n+1){
     width: 250px;
  }
  &:nth-child(4n+2){
     width: 325px;
  }
  &:nth-child(4n+3){
     width: 180px;
  }
  &:nth-child(4n+4){
     width: 380px;
  }
}

.masonry-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/*
.masonry--h {
  flex-flow: row wrap;
}

.masonry--v {
  flex-flow: column wrap;
  max-height: 1080px;
}

.masonry--h,
.masonry--v {
  margin-left: -8px; /* Adjustment for the gutter 
  counter-reset: brick;
}
*/

//blog
.smooting-corners-blog-1 {
  border-radius: 0px 50px;
  
  @include breakpoint(small down) {
    margin-top:15px;
  }
  @include breakpoint(medium down) {
    margin-top:15px;
  }
}
.smooting-corners-blog-2 {
  margin-top: 13px;
  border-radius: 50px 0px;
}

.blog-post-text{
  text-align:lef   t;

  h1{
    font-size: 40px;
    text-align: center;
  }

  h2{
    font-size: 30px;
  }

  h3{
    font-size: 24px;
  }
}

.blog-post-summary-container {

  &:not(.video-div) {

  &:hover {
    .blog-post-image div { 
      transform: scale(1.1); 
    }
  }

  }

}

.blog-post-summary-container .blog-post-image div{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: inherit;
    transition: all .3s ease-out;
}

.blog-post-summary-container .blog-post-image {
  height: 250px;
  overflow: hidden;
  @include breakpoint(small down) {
    height: 110px;
  }
}

.blog-post-summary-container .blog-post-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

}

.blog-post-summary-container .blog-post-text {
  margin-top: 15px;
}

.pagination a {
  @include brandon-regular;
  transition: all .15s ease-in-out;
  border:none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination a:hover {
  background-color: $gold;
  color: $white;
}

.pagination .active a, .pagination a:hover {
  border-radius: 50%;
  background-color: $gold;
  color: $white;
}

.space-30-responsive{
  margin-bottom: 30px;
  @include breakpoint(small down) {
    margin-bottom: 10px;
  }
}

/*
.pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:60px 0px;

    li {
      display: inline-block;
      margin: 0 8px;
    }

    a {
      @include brandon-regular;
      @include transition-basic;
      border: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

        &:hover {
          background-color: $gold;
          color: $white;
          border-radius: 50%;
        }
        &.active{
          background-color: $gold;
          color: $white;
          border-radius: 50%;
        }
    }

    img {
      width: 8px;
    }
}

*/
//blog article

.title-article-blog{
  text-align:center;
}

.title-article-blog{
  text-align:center;
}

.text-article-blog{
  text-align:center;
}

.search-store-box {

  margin: 10px;
  padding: 15px;

  @include breakpoint(small down) {
    margin: 0px;
  }

}

.centered-padding-paragraph{
  padding: 0 20%;
  @include breakpoint(small down) {
    padding: 0px;
  }
}

#blog-banner-cms {

  @include breakpoint(medium down) {
    order: 2;
    text-align: center;
  }

  .item {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

  .item-1 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 10px 0px 0px;
    }
  }

  .item-2 {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      margin: 20px 0px 0px 10px;
    }
  }

  img {

    @include breakpoint(small down) {
      min-height: 240px;
    }

  }

}

.velluti-blocco {
  align-self: center;
}

.blog-social__button {
  transition: all .15s ease-out;
  border: 1px solid;
  border-radius: 2px;
  padding: 13px 40px;
  position: relative;
  display: inline-block;
}

#text-block-banner-1, #text-block-banner-2 {

  margin-left: 10px;

  @include breakpoint(small down) {
    margin-left: 0px;
  }
}

#last-post {

  @include breakpoint(small down) {
    margin-bottom: 10px;
  }

}

.related-post-title {
  padding: 25px 0;
  border-top: 1px solid $dark-grey;
  margin-top: 60px;
}

#map {
  height: 100%;
}

.map-container {

  padding: 10px 0 10px 10px;

  @include breakpoint(small down) {
    padding: 0px;
  }

}

.store-overflow {
  overflow: auto;
  height: calc(100vh - 152px);

  @include breakpoint(small down) {
    overflow: unset;
    height: auto;
  }

}

.store-container {
  margin:10px;
  padding:10px;
  display:flex;
  flex-direction:column;
  @include breakpoint(small down) {
    margin: 0;
  }
}

.store-container-torino {
    margin: 10px;
    padding: 10px;
    display: flex;
    border:1px solid $gold;
  }
  
  
.img_store-container-torino{
  @include breakpoint(small down) {
    display:none;
}
}

#blog-banner-1 {
  height: 25vh;
  margin-bottom: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-bottom: 0px;
  }

  @include breakpoint(small down) {
    padding-bottom: 5px;
  }


}

#blog-banner-2 {
  height: 24vh;
  margin-top: 1vh;

  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-top: 0px;
  }

  @include breakpoint(small down) {
    padding-top: 5px;
  }
}

.text-center{
  text-align:center;
}

//reserved area

.reserved-area-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

  .sub-title-text-centered p {
    padding: 0px;
    line-height: 1.7;
    margin: 6px;
  }

  
  //press
   
  .row.press {

    .summary {
      h2 {
        font-size: 2em;
      }
      .blog-date {
        font-weight: bold;
      }
    }

  }

  .image-cover-blog{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  }

  //vetrine


.title-vetrine {
  font-size: 30px;
  margin-bottom: 15px;
 }
 
 .subtitle-vetrine {
  font-size: 16px;
  margin-bottom: 20px;
  @include breakpoint(medium down) {
    margin-bottom: 10px;
  }
 }
 
 .paragrafo-vetrine{
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  @include breakpoint(medium down) {
    margin-bottom: 10px;
  }
 }

 .debug .hot-spot .tooltip {
   display: block;
 }

 .txt-vetrine{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  background-color: $dark-grey;
  @include breakpoint(medium down) {
    height: 370px;
  }
 }
   /*
  padding: 3.5em 1em;
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    @include breakpoint(medium down) {
      padding: 1.2em 2.5em;
    }
 }
 */

 .margin-vetrine{
   margin-bottom: 50px;
   @include breakpoint(medium down) {
    margin-bottom: 30px;
  }
 }
 
 .vetrine-subtitle-page{
  line-height: 1.7em;
  padding: 0px 20px;
 }

 .txt-container-vetrine{
  padding: 0 2em;
 }

 .back-shop-window{
  display: flex;
  justify-content: flex-end;
 }

 .didascalia-vetrina p{
  @include brandon-regular;
  font-size: 18px;
 }

 .main-menu .opipromo {
  color: $gold;
  display: inline;
  text-transform: initial;
}

  #side-popup {
  position: fixed;
  right: 15px;
  bottom: 15px;
  background-color: $white;
  z-index: 99;
  width: 360px;
  text-align: center;
  padding: 35px;
  border: 1px solid $gold;
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.2);
  
  @include breakpoint(small down) {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  /* -webkit-animation-name: e;
  animation-name: e;
  -webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; */

}

#close-side-popup {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
  width: 20px;
  transition: opacity .15s;
}

.div-text-slider {
  position: absolute;
  width: 100%;
  top: 50%;
}
.paragraph-slider-home p{
  font-size: 18px;
  color: $white;
  margin-top: 20px;

  @include breakpoint(small down) {
    font-size: 18px;
   }
}

.title-slider-home{
  color: $white;
  font-size:20px;
}

  //sustainability

  .image-link-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
  }