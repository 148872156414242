// button
.btn {
    @include brandon-medium;
    @include transition-basic;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.12em;
    display: inline-block;
    padding: 5px 0 5px;
    border: none;
    cursor: pointer;
    text-align: center;

    &.btn-primary {
        background-color: $gold;
        border: 1px solid $gold;
        color: $white;
        padding: 10px 0px;

        &:hover {
            background-color: $gold-hover;
        }
    }

    &.btn-facebook {
        border: 1px solid $blue-facebook;
        color: $blue-facebook;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            color: $blue-facebook-hover;
        }
    }

    &.btn-secondary {
        color: $gold;
        text-align: left;

        &.arrow-link {
            text-align: center;
        }

        &:hover {
            color: $gold-hover;
        }
    }

    &.btn-third {
        border: 1px solid $gold;
        color: $black;
        padding: 10px 0px;

        &:hover {
            color: $black-hover;
            border: 1px solid $gold-hover;
        }
    }

    &.btn-payments {
        color: $black;
        padding: 10px 0px;
        background-color: $grey-paypal;

        &:hover {
            box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.05);
        }
    }

    &.btn-padding {
        padding-left: 45px;
        padding-right: 45px;
    }

    &.btn-orders {
        padding: 6px 10px;
    }

    &:active {
        position: relative;
        top: 1px;
    }

    &:disabled {
        opacity: 0.7;
    }
}

// input

.input-text {
    label {
        @include transition-basic;
        top: 6px;
        left: 14px;
        text-transform: uppercase;
        color: $black;
        font-size: 14px;
    }

    input,
    textarea {
        @include transition-basic;
        box-shadow: none;
        border: 1px solid $dark-grey;
        border-radius: 3px;
        padding: 15px 10px;
        height: auto;

        &:focus {
            border-color: $gold;
            box-shadow: none;

            + label {
                color: $gold;
            }
        }
    }
}

/*
.label-input {

    label {
      transition: all .15s ease-out;
      position: absolute;
      top: 6px;
      left: 12px;
      text-transform: uppercase;
      color: pink;
      font-size: 14px;
    }

    input,
    textarea,
    select {
      @include brandon-bold;
      box-shadow: none;
      border-color: #e4e6eb;
      font-size: 14px;
      letter-spacing: 0.05em;
      padding-left: 12px;

        &::-webkit-input-placeholder {
          @include brandon-regular;
        }
        &::-moz-placeholder {
          @include brandon-regular;
        }
        &:-ms-input-placeholder {
          @include brandon-regular;
        }
        &:-moz-placeholder {
          @include brandon-regular;
        }
    }
}

/*
.input{
  transition: all .15s ease-out;
  box-shadow: none;
  border: 1px solid #ccceda;
  border-radius: 3px;
  padding: 25px 10px 6px;
  height: auto;
}

.textarea{
  border: 1px solid $gold;
}

*/

// checkbox
.styled-checkbox {
    position: absolute;
    opacity: 0;

    & + label,
    & + label a {
        position: relative;
        padding: 0;
        margin: 5px 0;

        &.is-invalid-label,
        &.is-invalid-label a {
            color: $red;
        }
    }

    & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        background: none;
        border: 1px solid $gold;
        position: relative;
        top: -4px;
    }

    &:hover + label:before {
        background: $gold;
    }

    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
        background: $gold;
    }

    &:disabled + label {
        color: $light-grey;
        cursor: auto;
    }

    &:disabled + label:before {
        box-shadow: none;
        background: $light-grey;
    }

    &:checked + label:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 8px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white,
            4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
        transform: rotate(45deg);
    }

    &.error + label {
      color: $red;
    }
}

input[type="checkbox"] + label {
    margin: 0;
    position: relative;
    cursor: pointer;
    float: left;
    font-size: 14px;
    color: $black;
    line-height: 1.5;
    margin-bottom: 20px;
    @include breakpoint(small down) {
        font-size: 14px;
    }

    a {
        color: $gold;
    }
}

input[readonly] {
    &.datepicker.picker__input {
        background-color: $white;
    }
}

//background input
input,
textarea {
    // Disabled/readonly state
    &:disabled,
    &[readonly] {
        background: $light-grey;
    }
}

//tasto esci profilo
[type="submit"],
[type="button"] {
    appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0px;
    cursor: pointer;
    background: none;
    padding: 10px 0px;
}

//select
.select {
    height: auto;
    border: 1px solid $dark-grey;
    @include brandon-regular;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 3px;

    &:focus {
        border: 1px solid $gold;
        box-shadow: none;
    }
}

.radio {
    margin: 0.1rem 0;
    margin-bottom: 10px;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .radio-label {
            margin: 0;
            text-transform: uppercase;
            &:before {
                content: "";
                background: none;
                border-radius: 100%;
                border: 1px solid $gold;
                display: inline-block;
                width: 1.3em;
                height: 1.3em;
                position: relative;
                top: 1px;
                margin-right: 8px;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
                vertical-align: text-top;
            }
        }
        &:checked {
            + .radio-label {
                &:before {
                    background-color: $gold;
                    box-shadow: inset 0 0 0 4px $light-gray;
                }
            }
        }
        &:focus {
            + .radio-label {
                &:before {
                    outline: none;
                    border-color: $gold;
                }
            }
        }
        &:disabled {
            + .radio-label {
                &:before {
                    box-shadow: inset 0 0 0 4px $light-gray;
                    border-color: darken($light-gray, 25%);
                    background: darken(white, 25%);
                }
            }
        }
        + .radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}

//select
select {
    border: 1px solid $dark-grey;
    height: 3.375rem;
    border-radius: 3px;
    &:focus {
        border: 1px solid $gold;
        box-shadow: none;
    }
}

//accordion
.accordion-title {
    border: none;
    color: $black;
    font-size: 20px;
    padding: 1rem 0rem;
    @include caslon;
    &:hover,
    &:active,
    &:focus {
        background-color: $white;
        color: $gold;
    }
    :last-child .is-active {
        border-bottom: 1px solid $dark-grey;
    }
}

.accordion-content {
    border: none;
    padding: 1rem 0rem;
}

//newsletter
#newsletter-step2 {
    display: none;
    @include breakpoint(medium down) {
        margin: 0 30px;
    }

    .button.button-form {
        width: 100%;
    }
}

.container-input-newsletter {
    position: relative;
    @include breakpoint(medium down) {
        margin: 0 30px;
    }
}

#newsletter-arrow {
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 14px;
    cursor: pointer;
    transition: opacity 0.4s ease;
    & img {
        width: 30px;
    }
}

#success-message {
    @include brandon-medium;
    color: $success;
    text-transform: uppercase;
    text-align: right;
    font-size: 18px;
    margin: 0;
    display: none;
}
