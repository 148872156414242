// main header
.header-main {
  z-index: 100;
  background-color:#fefefe;
  border-bottom: 1px solid $line-color;
}

.header-main.sticky {
  position: fixed;
  width: 100%;
  top: 0;
}

//right part

.header-main__side {

  &:hover .profile-menu-drop {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
      
  }

  .profile-menu-drop {
      position: absolute;
      /* transition: all .5s; */
      left: -20px;
      right: auto;
      top: 25px;
      margin: 0 auto;
      background-color: #ffffff;
      width: 60px;
      pointer-events: none;
      text-align: center;
      padding: 20px 5px 5px 5px;
      z-index: 10;
      opacity: 0;
      transform: translateY(-110px);

        &.drop-language {
          left: -24px;
          width: 68px;
        }

        &.drop-price {
          left: -19px;
          width: 120px;
          background-color: $light-grey;
          border: 1px solid $text-grey;
          width: 120px;
          text-align: center;
          padding: 14px 0px 0px 0px;
          z-index: 20;
        }

  }

  input {
      background-color: #fff;
      width: 92%;
      color: $black;
      border: none;
      cursor: pointer;
      transition: all .15s;
      background-color: $light-grey;
      width: 120px;
      text-align: center;
      padding: 5px 0;

      &:hover {
          color: $gold;
      }
  }

  a:hover {
      color: $gold;

  }
}

.top-banner{
  background-color: $violet;
    padding: 6px 32px;

    p {
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $white;
    }
}


.count-wishlist{
  position: absolute;
  font-size: 12px;
  background-color: $light-violet;
  color: $white;
  display: block;
  top: -1px;
  left: 20px;
  height: 15px;
  width: 15px;
  text-align: center;
  border-radius: 43px;
  line-height: 17px;
  padding: 0 3px;
  box-shadow: -1px 1px 0 $white;
}