//Subscribe
.list {
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    display: flex;
    padding: 0.5em;
    width: 100%;
}

.list-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.list-content p {
    flex: 1 0 auto;
}

.label-subscribe {
    position: absolute;
    top: 22%;
    text-align: center;
    width: 100%;
    h2 {
        font-size: 32px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    h3{
        font-size: 24px;
        color: $white;
        text-shadow: 0px 2px 5px $black;
    }

    &.second {
        top: 29%;
    }

    p{
        text-shadow: 0 2px 5px $black;
        color: white;
        margin: 20px 28px;
        font-size: 18px;
    }
}

.subscribe-home-blog {
    img {
        height: 158.14px;
        object-fit: cover;
    }
}

//mansory homepage

.mansory-gallery-blog {
    margin-top: 50px;
    @include breakpoint(small down) {
        margin-top: 10px;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.mansory-gallery {
    margin-top: 50px;

    .tessuti {
        @include breakpoint(small down) {
            margin-bottom: 10px;
        }
    }

    .cuscini {

        @include breakpoint(small down) {
            margin-bottom: 30px;
        }
    }

    img {
        @include breakpoint(small down) {
            height: 140px;
            width: 100%;
            object-fit: cover;

        &.mansory-first-img {
            height: 450px;
            }
        }
    }
}

.dida-mansory {
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: $dark-grey;
    padding: 10px 10px 3px 10px;
    text-align: center;
}

.sub-title-mansory {
    h3 {
        font-size: 24px;
    }
}

/*
.tessuti-mansory{
position: relative;
background-image: url(/skins/b2bopificio/images/home/tessuti-2.jpg);
position: relative;
background-position: center center;
background-size: cover;
height:526px;
}

.velluti-mansory{
    position: relative;
    background-image: url(/skins/b2bopificio/images/home/velluti.jpg);
    position: relative;
    background-position: center center;
    background-size: cover;
    height:205px;
    }
    .cuscini-mansory{
        position: relative;
        background-image: url(/skins/b2bopificio/images/home/cuscini.jpg);
        position: relative;
        background-position: center center;
        background-size: cover;
        height:215px;
        }

        .trapuntini-mansory{
            position: relative;
            background-image: url(/skins/b2bopificio/images/home/trapuntini.jpg);
            position: relative;
            background-position: center center;
            background-size: cover;
            height:215px;
            }
    
*/
.home-hero__text {
    padding: 50px 0px;

    &.privato{
        padding: 50px 40px;
        text-align: center;

        p {
            font-size: 18px;
            @include breakpoint(medium down) {
                padding: 15px 0;
            }
        }
        @include breakpoint(medium down) {
            padding: 23px 30px;
        }
        
    }


    &.login {
        display: flex;
        justify-content: center;
        padding: 0;

        @include breakpoint(medium down) {
            padding: 0;
            min-height:0px;
        }

        p {
            color: $black;
            padding-top:0px;
        }

         h2 {
             color: $gold;
             @include breakpoint(medium down) {
                padding: 0px;
            }
         }
    }

    &.newsletter{
        text-shadow: none;
        h2 {
            font-size: 30px;
            color: $black;
            
            @include breakpoint(large down) {
                padding: 0px 30px;
            }
    
            @include breakpoint(medium down) {
                padding: 0px 30px;
            }
        }

        p {
            color: $black;
            padding-top: 15px;
    
            @include breakpoint(large down) {
                padding: 15px 30px;
            }
    
            @include breakpoint(medium down) {
                padding: 15px 30px;
                margin-bottom: 0px;
            }
        }
    }

    @include breakpoint(large down) {
        padding: 42px 0px;
    }

    @include breakpoint(medium down) {
        padding: 42px 0px;
    }

    h2 {
        font-size: 30px;
        color: $white;
        
        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }

    p {
        color: $white;
        padding-top: 15px;

        @include breakpoint(large down) {
            padding: 15px 30px;
        }

        @include breakpoint(medium down) {
            padding: 15px 30px;
            margin-bottom: 0px;
        }
    }


    .btn.btn-secondary {
        padding-top: 30px;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }
 
}

.button-home_subscribe{
    padding: 15px 0;
}

.btn-register-home{
    width: 97%;
    &.professionista {
        width: 99%;
    }
}
.home-hero__text .date-landing-page{
    font-size:25px;
    padding-left: 8px;
    border-left:3px solid $gold;
    margin-bottom: 0px;
    padding-top: 0px;

    @include breakpoint(small down) {
     font-size:21px;
    }

}

.cms-wrapper .home-hero__image .home-hero__text{
    p {
        @include breakpoint(medium down) {
            padding:0px;
            padding-top: 20px;
            margin-bottom:20px;
        }
    }

}
/* .date-landing-page .margin-top-30 {
    @include breakpoint(medium down) {
        padding-top:0px;
    }

}
*/

.margin-bottom-privato{
    margin-bottom:47px;
}

.home-hero__image {
    background-image: url("/skins/b2bopificio/images/home/alt-hero-image-1.png");
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;

    &.designer {
        background-image: url("/skins/b2bopificio/images/home/alt-hero-image-2.jpg");
    }

    &.newsletter{
        background-image: url("/skins/b2bopificio/images/home/newsletter.jpg");
    }

    &.login {
        min-height: 100vh;
    }
}

//landing page
.landing-hero__text {
    padding: 72px 0px;
    text-shadow: 0 1px 10px $black;

    @include breakpoint(large down) {
        padding: 42px 0px;
    }

    @include breakpoint(medium down) {
        padding: 42px 0px;
    }

    h2 {
        font-size: 30px;
        color: $white;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }

    p {
        color: $white;

        @include breakpoint(large down) {
            padding: 15px 30px;
        }

        @include breakpoint(medium down) {
            padding: 15px 30px;
            margin-bottom: 0px;
        }
    }


    .btn.btn-secondary {
        padding-top: 30px;

        @include breakpoint(large down) {
            padding: 0px 30px;
        }

        @include breakpoint(medium down) {
            padding: 0px 30px;
        }
    }
 
}
.landing-hero__text .date-landing-page{
    font-size:25px;
    padding-left: 8px;
    border-left:3px solid $gold;
    margin-bottom: 0px;
    padding-top: 0px;

    @include breakpoint(small down) {
     font-size:21px;
    }

}

.landing-hero__text{
    p {
        @include breakpoint(medium down) {
            padding:0px;
            margin-bottom:20px;
        }
    }

}
/* .date-landing-page .margin-top-30 {
    @include breakpoint(medium down) {
        padding-top:0px;
    }

}
*/


.landing-hero__image {
    background-image: url("/skins/b2bopificio/images/home/alt-hero-image-1.png");
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100%;
}

//4 comuns image
.img-4-comuns {
    width: 100%;
}

.blog {
    h3 {
        font-size: 24px;
        padding: 10px 0px;
    }
}

.date {
    font-size: 14px;
    @include brandon-medium;
    padding-top: 10px;
}

.description-blog {
    font-size: 16px;
    padding-top: 10px;
    text-align: left;
}



//video ebt
.ebt-box {
    position: relative;
    display: flex;
    padding: 30px;
    background-image: url("/skins/b2bopificio/images/home/ebt-2.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    justify-content: space-between;
    align-items: flex-end;
}

.ebt-box-text {
    width: 30%;

    span {
        color: $white;
        letter-spacing: .15em;
        text-shadow: 0 4px 4px $white;
        color: $black;
    }

    h2 {
        color: $black;
        font-size: 30px;
        margin-top: 15px;
        margin-bottom: 15px;
        text-shadow: 0 4px 4px $white;
    }

    p {
        color: $black;
        font-size: 16px;
        text-shadow: 0 4px 4px $white;
    }
}

.ebt-box-video {
    width: 60%;
}

#ebt-video {
    opacity: 0;
}

video {
    display: inline-block;
    vertical-align: baseline;
}

#play-button {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80px;
    height: 80px;
    cursor: pointer;

}

@include breakpoint (small down) {
    .ebt-box-text {
        display: none;
    }

    .ebt-box-video {
        width: 100%;
    }
}


.banner-text {
    padding: 10px 0px 10px 0px;
    text-align: center;
}


//instagram social wall
#instafeed>div {
    margin-bottom: 30px;
}

//slider

#img-slider {

    opacity: 0;
    transition: 1s;

    .slick-slide {
        opacity: 0.5;
        min-height: none;
        width:1080px;
        /*height: 530px;*/
        @include breakpoint (medium down) {
            opacity: 1;
        }

    }
    .slick-center {
        opacity: 1;
    }

    .slick-dots li button:before {
        color: $black;
        font-size: 10px;
        line-height: 30px;
    }

    .slick-dots li.slick-active button:before {
        color: $gold;
    }

    .slick-next:before,
    .slick-prev:before {
        color: $gold;
        font-size: 40px;
    }

    .slick-prev {
        left: 40px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
           display: none!important;
        }
    }

    .slick-next {
        right: 60px;
        height: 40px;
        width: 40px;
        z-index: 1;
        @include breakpoint (medium down) {
            display: none!important;
         }
    }

}

.slick-prev:before {
    content: url(/skins/b2bopificio/images/icons/freccia-sx-slider.svg) !important;
}

.slick-next:before {
    content: url(/skins/b2bopificio/images/icons/freccia-dx-slider.svg) !important;
}

.sample-slick {
    margin-right: 30px;
    .slick-next {
        right: -22px;
    };
    .slick-prev {
        display: none !important;
    }
}

//img categorie

.img-categories{
    min-height: 160px;
    text-align: center;
}

.hotspot-container{
    position: relative;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
  }
  
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


//slider

.slick-slide .HotspotPlugin_Hotspot{
    background-image: url(/skins/b2bopificio/images/icons/marker-sllider.svg);
    border-radius: 50%;
    position: absolute;
    height: 25px;
    width: 26px;
    }
    .slick-slide .HotspotPlugin_Hotspot>div>.Hotspot_Title{
    background: $black;
    font-size: 22px;
    color: $white;
    text-align: center;
    height: 100%;
}

.slick-slide .Hotspot_Title{
    a{
        color: $white;
    }
}
.HotspotPlugin_Hotspot{
    background-color: $black;
}

.hotspot-container .HotspotPlugin_Hotspot>div {
        width: 210px;
        height: 50px;
        margin: -54px -86px;
    }

.box-professionista{
    background-image: url(/skins/b2bopificio/images/home/architetti.jpg);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

.box-privato{
    background-image: url(/skins/b2bopificio/images/home/privati.jpg);
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}