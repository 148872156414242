#map{
  /*
  height:50vh;
  */
  min-height: 400px;
}

#directions{
  margin-bottom: 9px;
  margin-top: 12px;
}

.form-popup {
  display: none;
  border: 3px solid #f1f1f1;
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  width:300px;
}

.information-boutique{
overflow: auto;
padding: 42px 28px;
background-color: $dark-grey;
}

.medium-paragraph{
  @include brandon-medium;
}


