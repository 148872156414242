.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include caslon;
}

.checkout-active {
  border-bottom: 1px solid $line-color;
  padding: 15px 0 7px;
  margin: 0 0 35px;
  color: $black;
  font-size: 30px;
}

.checkout-step {
  padding: 15px 0 7px 40px;
  position: relative;
  letter-spacing: .01em;
  border-bottom: 1px solid $line-color;
  color: $black;
  font-size: 25px;

    &.active {
      margin: 0 0 25px;
      color: $black;
    }

    &::before {
      @include brandon-medium;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 16px;
      position: absolute;
      border: 1px solid $gold;
      left: 0;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      color: $black;
      border: 1px solid $gold;
    }

    &.first::before {
      content: "1";
    }
    &.second::before {
      content: "2";
    }
    &.third::before {
      content: "3";
    }

    &.active::before {
      color: $black;
      background-color: $gold;
      color: $white;
    }
}

.checkout-buttons {
  margin: 0 0 40px;

    .btn {
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}

.payment-table {
  display: flex;
  padding-bottom: 20px;
}

.payment-table {
  p {
    margin-bottom: 4px;
  }
}

p.orders-details {
  font-size: 14px;
  margin-bottom: 6px;
}